
export default class StaticData {
    static data = {
        'US-AK': { lat: 61.3707, lon: -152.404, zoom: 4 },
        'US-KH8': { lat: -14.2893, lon: -170.693, zoom: 6 },
        'US-KH': { lat: 0.801163, lon: -176.634, zoom: 6 },  // KH1
        'US-KP5': { lat: 18.3839, lon: -67.4805, zoom: 6 },
        'US-KH2': { lat: 13.4443, lon: 144.794, zoom: 6 },
        'US-HI': { lat: 21.0943, lon: -157.498, zoom: 8 },
        'US-KH3': { lat: 16.7369, lon: -169.524, zoom: 6 },
        'US-KH5K': { lat: 6.41582, lon: -162.439, zoom: 6 },
        'US-KH7K': { lat: 34.2484, lon: 132.565, zoom: 6 },
        'US-KH4': { lat: 28.2, lon: -177.35, zoom: 6 },
        'US-KP1': { lat: 18.4084, lon: -75.0144, zoom: 6 },
        'US-KH5': { lat: 5.8822, lon: -162.075, zoom: 6 },
        'US-KP': { lat: 18.2208, lon: -66.5901, zoom: 6 },  // KP4
        'US-AL': { lat: 32.8067, lon: -86.7911, zoom: 7 },
        'US-AZ': { lat: 33.7298, lon: -111.431, zoom: 7 },
        'US-AR': { lat: 34.9697, lon: -92.3731, zoom: 7 },
        'US-CA': { lat: 36.1162, lon: -119.682, zoom: 6 },
        'US-CO': { lat: 39.0598, lon: -105.311, zoom: 7 },
        'US-CT': { lat: 41.5978, lon: -72.7554, zoom: 8 },
        'US-DE': { lat: 39.3185, lon: -75.5071, zoom: 9 },
        'US-DC': { lat: 38.8974, lon: -77.0268, zoom: 9 },
        'US-FL': { lat: 27.7663, lon: -81.6868, zoom: 7 },
        'US-GA': { lat: 33.0406, lon: -83.6431, zoom: 7 },
        'US-ID': { lat: 44.2405, lon: -114.479, zoom: 6 },
        'US-IL': { lat: 40.3495, lon: -88.9861, zoom: 7 },
        'US-IN': { lat: 39.8494, lon: -86.2583, zoom: 7 },
        'US-IA': { lat: 42.0115, lon: -93.2105, zoom: 7 },
        'US-KS': { lat: 38.5266, lon: -96.7265, zoom: 7 },
        'US-KY': { lat: 37.6681, lon: -84.6701, zoom: 7 },
        'US-LA': { lat: 31.1695, lon: -91.8678, zoom: 7 },
        'US-ME': { lat: 44.6939, lon: -69.3819, zoom: 7 },
        'US-MD': { lat: 39.0639, lon: -76.8021, zoom: 8 },
        'US-MA': { lat: 42.2302, lon: -71.5301, zoom: 8 },
        'US-MI': { lat: 43.3266, lon: -84.5361, zoom: 7 },
        'US-MN': { lat: 45.6945, lon: -93.9002, zoom: 7 },
        'US-MS': { lat: 32.7416, lon: -89.6787, zoom: 7 },
        'US-MO': { lat: 38.4561, lon: -92.2884, zoom: 7 },
        'US-MT': { lat: 46.9219, lon: -110.454, zoom: 6 },
        'US-NE': { lat: 41.1254, lon: -98.2681, zoom: 7 },
        'US-NV': { lat: 38.3135, lon: -117.055, zoom: 7 },
        'US-NH': { lat: 43.4525, lon: -71.5639, zoom: 8 },
        'US-NJ': { lat: 40.2989, lon: -74.521, zoom: 8 },
        'US-NM': { lat: 34.8405, lon: -106.248, zoom: 7 },
        'US-NY': { lat: 42.1657, lon: -74.9481, zoom: 7 },
        'US-NC': { lat: 35.6301, lon: -79.8064, zoom: 8 },
        'US-ND': { lat: 47.5289, lon: -99.784, zoom: 7 },
        'US-OH': { lat: 40.3888, lon: -82.7649, zoom: 8 },
        'US-OK': { lat: 35.5653, lon: -96.9289, zoom: 7 },
        'US-OR': { lat: 44.572, lon: -122.071, zoom: 7 },
        'US-PA': { lat: 40.5908, lon: -77.2098, zoom: 7 },
        'US-RI': { lat: 41.6809, lon: -71.5118, zoom: 8 },
        'US-SC': { lat: 33.8569, lon: -80.945, zoom: 7 },
        'US-SD': { lat: 44.2998, lon: -99.4388, zoom: 7 },
        'US-TN': { lat: 35.7478, lon: -86.6923, zoom: 8 },
        'US-TX': { lat: 31.0545, lon: -97.5635, zoom: 6 },
        'US-UT': { lat: 40.15, lon: -111.862, zoom: 7 },
        'US-VT': { lat: 44.0459, lon: -72.7107, zoom: 8 },
        'US-VA': { lat: 37.7693, lon: -78.17, zoom: 7 },
        'US-WA': { lat: 47.4009, lon: -121.49, zoom: 7 },
        'US-WV': { lat: 38.4912, lon: -80.9545, zoom: 8 },
        'US-WI': { lat: 44.2685, lon: -89.6165, zoom: 8 },
        'US-WY': { lat: 42.756, lon: -107.302, zoom: 7 },
        'US-VI': { lat: 18.3358, lon: -64.8963, zoom: 8 },
        'US-KH9': { lat: 19.2899, lon: 166.649, zoom: 8 }
    };
}